/* Alumni Section Styles */

.alumni-section {
  background: linear-gradient(to bottom, #ffffff, #f8faff, #ffffff);
  position: relative;
  overflow: hidden;
}

/* Background pattern */
.alumni-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b82f6' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.4;
  z-index: 0;
}

/* Section header */
.alumni-section .section-header {
  position: relative;
  z-index: 1;
}

/* Underline accent */
.alumni-section .accent-underline {
  height: 4px;
  width: 80px;
  background: linear-gradient(to right, #ff6b00, #ff9d00);
  border-radius: 2px;
  margin-top: 0.5rem;
}

/* Logo container */
.alumni-logo-container {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

/* Logo grid */
.alumni-logo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 2rem;
  align-items: center;
  justify-items: center;
}

/* Logo item */
.alumni-logo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  padding: 1rem;
  border-radius: 12px;
  background: white;
  transition: all 0.3s ease;
  filter: grayscale(100%);
  opacity: 0.7;
}

.alumni-logo-item:hover {
  filter: grayscale(0%);
  opacity: 1;
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.alumni-logo-item img {
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
}

/* Marquee animation */
.marquee-container {
  position: relative;
  overflow: hidden;
  --gap: 2rem;
  display: flex;
  user-select: none;
  gap: var(--gap);
}

.marquee {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 30s linear infinite;
}

.marquee-reverse {
  animation-direction: reverse;
  animation-duration: 40s;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

/* Pause on hover */
.marquee-container:hover .marquee {
  animation-play-state: paused;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .alumni-logo-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
  }
  
  .alumni-logo-item {
    height: 80px;
    padding: 0.5rem;
  }
  
  .alumni-logo-item img {
    max-height: 60px;
  }
} 