/* Events Section Styles */
.events-section {
  position: relative;
  overflow: hidden;
}

/* Event Card Styles */
.event-card {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 2rem;
  min-height: 300px;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
  border-color: rgba(59, 130, 246, 0.3);
}

/* Add text shadow to improve visibility */
.text-shadow-lg {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.event-time-badge {
  background: rgba(30, 58, 138, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 0.75rem;
  padding: 0.75rem 1.5rem;
  border: 1px solid rgba(59, 130, 246, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.event-card:hover .event-time-badge {
  background: rgba(30, 58, 138, 0.6);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border-color: rgba(59, 130, 246, 0.4);
}

.event-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  color: white;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Speaker Card Styles */
.speaker-card {
  position: relative;
  background: rgba(30, 58, 138, 0.4);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  max-width: 300px;
  border: 1px solid rgba(59, 130, 246, 0.3);
}

.speaker-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.25);
  border-color: rgba(59, 130, 246, 0.4);
}

.speaker-image-container {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.speaker-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.speaker-card:hover .speaker-image {
  transform: scale(1.05);
}

.speaker-info {
  padding: 1.25rem;
  text-align: center;
  background: rgba(15, 23, 42, 0.9);
  backdrop-filter: blur(5px);
}

.speaker-name {
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.speaker-badge {
  display: inline-block;
  background: rgba(234, 179, 8, 0.4);
  color: #fde68a;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  border: 1px solid rgba(234, 179, 8, 0.3);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments for event card */
@media (max-width: 768px) {
  .event-card {
    padding: 1rem;
  }
  
  .speaker-card {
    margin: 0 auto;
  }
  
  .event-time-badge {
    padding: 0.5rem 1rem;
  }
  
  .event-action-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

/* Admission Section Styles */
.admission-section {
  position: relative;
  overflow: hidden;
}

/* Section header */
.admission-title {
  position: relative;
  margin-bottom: 2.5rem;
  text-align: center;
}

.admission-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, #ff6b00, #ff9d00);
  border-radius: 2px;
}

/* Course cards */
.course-card {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform: translateY(0);
  border: 1px solid rgba(229, 231, 235, 0.5);
}

.course-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  border-color: rgba(59, 130, 246, 0.3);
}

/* Course image */
.course-image {
  position: relative;
  height: 220px;
  overflow: hidden;
}

.course-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.course-card:hover .course-image img {
  transform: scale(1.05);
}

/* Course content */
.course-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));
}

.course-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 0.5rem;
  text-align: center;
  line-height: 1.4;
}

.course-faculty {
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 1rem;
  text-align: center;
}

.admission-status {
  text-align: center;
  margin: 1rem 0;
  font-weight: 600;
  color: #e53e3e;
  position: relative;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(254, 226, 226, 0.5);
}

/* Pulsing animation */
@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: scale(0.98);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.6;
    transform: scale(0.98);
  }
}

.pulse {
  animation: pulse 1.5s infinite ease-in-out;
}

/* Call to action button */
.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1DB954; /* Green color similar to the image */
  color: white;
  font-weight: 500;
  font-size: 0.95rem;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px; /* Fully rounded corners */
  transition: all 0.3s ease;
  margin: 1rem auto;
  width: fit-content;
  min-width: 150px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(29, 185, 84, 0.3);
}

.cta-button:hover {
  background-color: #19a34a; /* Slightly darker on hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 185, 84, 0.4);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(29, 185, 84, 0.3);
}

/* Card grid layout */
.course-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .course-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .course-card {
    max-width: 350px;
    margin: 0 auto;
  }
}

/* Badge */
.course-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  color: #3b82f6;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0.35rem 0.75rem;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: all 0.3s ease;
}

.course-card:hover .course-badge {
  background: #3b82f6;
  color: white;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

/* Animation for section elements */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Additional responsive styles */
@media (max-width: 480px) {
  .events-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .course-title {
    font-size: 1.1rem;
  }
  
  .course-image {
    height: 180px;
  }
} 