/* About Us Section Styles */

.about-section {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, #ffffff, #f8faff, #ffffff);
}

/* Background pattern */
.about-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b82f6' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.4;
  z-index: 0;
}

/* Section content */
.about-content {
  position: relative;
  z-index: 1;
}

/* Image container */
.image-container {
  position: relative;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.image-wrapper:hover {
  transform: translateY(-10px);
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.15);
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;
}

.image-wrapper:hover .about-image {
  transform: scale(1.05);
}

/* Stats badge */
.stats-badge {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #ffd700, #ffb700);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transform: rotate(12deg);
  z-index: 10;
  transition: all 0.3s ease;
}

.stats-badge:hover {
  transform: rotate(0deg) scale(1.1);
}

.stats-number {
  font-size: 2rem;
  font-weight: 700;
  color: #1e3a8a;
  line-height: 1;
}

.stats-text {
  font-size: 0.75rem;
  font-weight: 600;
  color: #1e3a8a;
  text-align: center;
  max-width: 80%;
}

/* Content styling */
.section-tag {
  display: inline-block;
  padding: 0.5rem 1.25rem;
  background-color: rgba(59, 130, 246, 0.1);
  color: #3b82f6;
  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 9999px;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1f2937;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

.section-title span {
  color: #3b82f6;
}

.section-description {
  font-size: 1.125rem;
  color: #4b5563;
  line-height: 1.7;
  margin-bottom: 2rem;
}

/* Feature items */
.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.feature-item {
  display: flex;
  flex-direction: column;
}

.feature-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.feature-icon {
  color: #3b82f6;
  margin-right: 0.75rem;
}

.feature-title {
  font-weight: 600;
  color: #374151;
}

.feature-description {
  font-size: 0.875rem;
  color: #6b7280;
  padding-left: 2rem;
}

/* CTA button */
.about-cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1DB954; /* Green color similar to the image */
  color: white;
  font-weight: 500;
  font-size: 0.95rem;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px; /* Fully rounded corners */
  transition: all 0.3s ease;
  margin-top: 1rem;
  width: fit-content;
  min-width: 150px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(29, 185, 84, 0.3);
}

.about-cta-button:hover {
  background-color: #19a34a; /* Slightly darker on hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 185, 84, 0.4);
}

.about-cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(29, 185, 84, 0.3);
}

/* Decorative elements */
.decorative-blob {
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  z-index: 0;
  opacity: 0.05;
}

.blob-1 {
  top: 10%;
  right: 5%;
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #3b82f6, #60a5fa);
}

.blob-2 {
  bottom: 10%;
  left: 5%;
  width: 250px;
  height: 250px;
  background: linear-gradient(45deg, #ffd700, #ffb700);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .section-title {
    font-size: 2rem;
  }
  
  .stats-badge {
    width: 80px;
    height: 80px;
  }
  
  .stats-number {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .feature-grid {
    grid-template-columns: 1fr;
  }
  
  .section-title {
    font-size: 1.75rem;
  }
  
  .section-description {
    font-size: 1rem;
  }
} 