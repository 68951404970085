/* Upcoming Admissions Section Styles */
.admissions-section {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  padding: 4rem 0;
}

/* Decorative elements */
.admissions-bg-circle-1 {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(224, 242, 254, 0.8), rgba(186, 230, 253, 0));
  top: -100px;
  right: -100px;
  z-index: 0;
}

.admissions-bg-circle-2 {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(254, 226, 226, 0.5), rgba(254, 202, 202, 0));
  bottom: -50px;
  left: -50px;
  z-index: 0;
}

/* Section header */
.admissions-title {
  position: relative;
  margin-bottom: 2.5rem;
  text-align: center;
}

.admissions-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, #3b82f6, #6366f1);
  border-radius: 2px;
}

/* Course cards */
.admission-card {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background: white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform: translateY(0);
  border: 1px solid rgba(229, 231, 235, 0.8);
  z-index: 1;
}

.admission-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
  border-color: rgba(59, 130, 246, 0.4);
}

/* Course image */
.admission-image {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.admission-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.admission-card:hover .admission-image img {
  transform: scale(1.05);
}

/* Course content */
.admission-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Course title */
.admission-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  position: relative;
}

/* Course badge */
.admission-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(to right, #3b82f6, #6366f1);
  color: white;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: all 0.3s ease;
}

.admission-card:hover .admission-badge {
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

/* Admission status */
.admission-status {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.admission-status-dot {
  width: 10px;
  height: 10px;
  background-color: #10b981;
  border-radius: 50%;
  margin-right: 0.5rem;
  animation: pulse 2s infinite;
}

.admission-status-text {
  font-size: 0.875rem;
  font-weight: 600;
  color: #10b981;
}

.admission-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.admission-info-item {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #4b5563;
}

.admission-info-icon {
  margin-right: 0.5rem;
  color: #6366f1;
}

.admission-description {
  font-size: 0.875rem;
  color: #6b7280;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.admission-actions {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* CTA Button */
.admission-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  gap: 0.5rem;
}

.admission-btn-primary {
  background: linear-gradient(to right, #3b82f6, #6366f1);
  color: white;
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
}

.admission-btn-primary:hover {
  background: linear-gradient(to right, #2563eb, #4f46e5);
  transform: translateY(-2px);
  box-shadow: 0 8px 12px rgba(59, 130, 246, 0.3);
}

.admission-btn-secondary {
  background: transparent;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.admission-btn-secondary:hover {
  background: #f9fafb;
  border-color: #d1d5db;
  color: #1f2937;
}

/* Card grid layout */
.admission-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

/* Animation keyframes */
@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animation classes */
.fade-in-up {
  animation: fadeInUp 0.8s ease forwards;
}

.fade-in-left {
  animation: fadeInLeft 0.8s ease forwards;
}

.fade-in-right {
  animation: fadeInRight 0.8s ease forwards;
}

/* Responsive styles */
@media (max-width: 768px) {
  .admission-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .admission-image {
    height: 180px;
  }
  
  .admission-content {
    padding: 1.25rem;
  }
}

@media (max-width: 480px) {
  .admissions-section {
    padding: 3rem 0;
  }
  
  .admission-grid {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
  
  .admission-title {
    font-size: 1.1rem;
  }
} 