/* Faculty Section Styles */

/* Section background */
.faculty-section {
  background: linear-gradient(to bottom, #ffffff, #f0f7ff, #ffffff);
  position: relative;
}

.faculty-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b82f6' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.4;
  z-index: 0;
}

/* Section header */
.faculty-section .section-header {
  position: relative;
  z-index: 1;
}

/* Decorative elements */
.faculty-section .decorative-blob {
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  z-index: 0;
  opacity: 0.1;
}

.faculty-section .blob-1 {
  top: 10%;
  right: 5%;
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #3b82f6, #60a5fa);
}

.faculty-section .blob-2 {
  bottom: 10%;
  left: 5%;
  width: 250px;
  height: 250px;
  background: linear-gradient(45deg, #ffd700, #ffb700);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faculty-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .faculty-section .decorative-blob {
    opacity: 0.05;
  }
} 