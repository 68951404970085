/* Faculty Swiper Styles */

/* Swiper container */
.faculty-swiper {
  padding: 20px 0;
}

/* Equal height slides */
.faculty-swiper .swiper-slide {
  height: auto;
  display: flex;
}

/* Navigation buttons */
.faculty-swiper .swiper-button-next,
.faculty-swiper .swiper-button-prev {
  color: #3b82f6;
  background: rgba(255, 255, 255, 0.9);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.faculty-swiper .swiper-button-next:after,
.faculty-swiper .swiper-button-prev:after {
  font-size: 18px;
  font-weight: bold;
}

.faculty-swiper .swiper-button-next:hover,
.faculty-swiper .swiper-button-prev:hover {
  background: #3b82f6;
  color: white;
  transform: scale(1.1);
}

/* Pagination bullets */
.faculty-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #3b82f6;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.faculty-swiper .swiper-pagination-bullet-active {
  opacity: 1;
  width: 20px;
  border-radius: 5px;
}

/* Faculty card styles */
.faculty-card {
  transition: all 0.3s ease;
  transform: translateY(0);
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 450px; /* Set a minimum height for all cards */
}

.faculty-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Faculty image styles */
.faculty-image {
  transition: all 0.3s ease;
  border: 3px solid transparent;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.faculty-card:hover .faculty-image {
  border-color: #3b82f6;
  transform: scale(1.05);
}

/* Quote icon animation */
.faculty-card:hover .quote-icon {
  transform: rotate(-10deg);
}

/* Social media icon animations */
.social-icon {
  transition: all 0.3s ease;
}

.social-icon:hover {
  transform: translateY(-3px);
  background-color: #dbeafe;
}

/* Description text */
.faculty-card .line-clamp-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 144px; /* Approximately 6 lines of text */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faculty-swiper .swiper-button-next,
  .faculty-swiper .swiper-button-prev {
    display: none;
  }
  
  .faculty-card {
    max-width: 320px;
    margin: 0 auto;
    min-height: 400px;
  }
  
  .faculty-image {
    width: 100px;
    height: 100px;
  }
} 